<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_breadcrumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/Admin/index' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>交易查询</el-breadcrumb-item>
                    <el-breadcrumb-item>交易明细</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="admin_main_block top">
            <el-row class="total-header">
                <el-col :span="span4" class="span4">
                    <el-row>
                        <el-col :span="span12">交易明细总笔数:</el-col>
                        <el-col :span="span10"><span>{{total.count}}</span></el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="span12">交易明细总金额:</el-col>
                        <el-col :span="span10" :show-overflow-tooltip="true"><span>{{total.amount}}</span></el-col>
                    </el-row>
                </el-col>
                <el-col :span="span4" class="span4">
                    <el-row>
                        <el-col :span="span12">渠道结算金额:</el-col>
                        <el-col :span="span10" :show-overflow-tooltip="true"><span>{{total.totalSettleFee}}</span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="span12">门店结算金额:</el-col>
                        <el-col :span="span10" :show-overflow-tooltip="true"><span>{{total.settlementAmount}}</span>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="span4" class="span4">
                    <el-row>
                        <el-col :span="span12">公司分账金额:</el-col>
                        <el-col :span="span10" :show-overflow-tooltip="true"><span>{{total.byFenzhangFee}}</span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="span12"> --</el-col>
                        <el-col :span="span10" :show-overflow-tooltip="true">--</el-col>
                    </el-row>
                </el-col>
            </el-row>
        </div>
        <div class="admin_main_block admin_m15">
            <div class="header-form">
                <div class="admin_main_block_left">
                    <el-form>
                        <el-form-item label="交易起止日期">
                            <el-date-picker
                                    v-model="date"
                                    @change="changeDate" @input="immediUpdate"
                                    type="daterange"
                                    range-separator="~"
                                    value-format="yyyy-MM-dd"
                                    format="yyyy-MM-dd"
                                    start-placeholder="开始日期"
                                    end-placeholder="截止日期"
                                    :clearable="true"
                                    unlink-panels
                                    :picker-options="pickerOptions"
                            >
                                <i class="el-icon-date"></i>
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="店铺ID">
                            <el-input
                                    v-model="where.obj.shopId"
                                    placeholder="请输入店铺ID"
                                    clearable
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="状态">
                            <el-select v-model="where.obj.status" clearable placeholder="状态">
                                <el-option label="全部" value=""></el-option>
                                <el-option
                                        v-for="(name,value) in orderStatusMap"
                                        :key="value"
                                        :label="name"
                                        :value="value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="交易类型">
                            <el-select v-model="where.obj.tradeType" placeholder="交易类型">
                                <el-option label="全部" value=""></el-option>
                                <el-option
                                        v-for="(name,value) in tradeTypeMap"
                                        :key="value"
                                        :label="name"
                                        :value="value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="金额">
                            <el-input
                                    v-model="where.obj.amount"
                                    placeholder="请输入金额"
                                    clearable
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="是否分账">
                            <el-select v-model="where.obj.profitSharing" placeholder="是否分账">
                                <el-option label="全部" value=""></el-option>
                                <el-option label="是" value="1"></el-option>
                                <el-option label="否" value="0"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="支付类型">
                            <el-select v-model="where.obj.payType" placeholder="支付类型">
                                <el-option label="全部" value=""></el-option>
                                <el-option
                                        v-for="(name,value) in payTypeMap"
                                        :key="value"
                                        :label="name"
                                        :value="value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <!--<el-form-item label="支付方式">
                            <el-select v-model="where.obj.payWay" placeholder="支付类型">
                                <el-option label="全部" value=""></el-option>
                                <el-option
                                        v-for="(name,value) in payWayMap"
                                        :key="value"
                                        :label="name"
                                        :value="value">
                                </el-option>
                            </el-select>
                        </el-form-item>-->
                        <el-form-item>
                            <el-button type="primary" plain icon="el-icon-search"
                                       @click="()=>{currentPage=1;listData();}"
                            >条件筛选
                            </el-button
                            >
                        </el-form-item>
                    </el-form>
                </div>
                <div class="admin_main_block_right">
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-button type="primary" icon="el-icon-more" @click="objFltrate.show = !objFltrate.show">
                                字段
                            </el-button>
                        </el-col>
                        <el-col :span="12">
                            <el-button
                                    type="primary"
                                    @click="exportData"
                                    icon="el-icon-tickets"
                                    :loading="download">导出数据
                            </el-button>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <div class="admin_table_main">
                <el-table
                        :data="list"
                        v-loading="loading"
                        element-loading-text="拼命加载中"
                        element-loading-spinner="el-icon-loading"
                        element-loading-background="rgba(0, 0, 0, 0.1)"
                >
                    <el-table-column label="序号" width="50px">
                        <template slot-scope="scope">
                            {{ scope.$index + 1 }}
                        </template>
                    </el-table-column>
                    <div v-for="items in objFltrate.itemsOptions" :key="items.value">
                        <el-table-column :prop="items.value" v-if="objFltrate.checkedCities.includes(items.value)"
                                         :label="items.name"></el-table-column>
                    </div>
                    <el-table-column prop="status" label="状态">
                        <template slot-scope="scope">
                            <span :class="'status'+scope.row.status">{{ scope.row.statusText }}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="admin_table_main_pagination">
                    <el-pagination
                            @current-change="currentChange"
                            @size-change="handleSizeChange"
                            background
                            :total="totalData"
                            :page-size="pageSize"
                            :current-page="currentPage"
                            :page-sizes="[10, 20, 50, 100]"
                            layout="sizes, prev, pager, next, total, jumper"
                    ></el-pagination>
                </div>
            </div>
        </div>
        <flteate v-if="objFltrate.show" :objFltrate="objFltrate"></flteate>
    </div>
</template>
<script>
    import {orderStatusMap, payTypeMap, payWayMap, tradeTypeMap} from "@/plugins/map.js";
    import {exportFile, formatDate, pickerOptions} from "@/plugins/function.js";
    import flteate from "@/components/admin/common/filtrate.vue";

    export default {
        components: {
            flteate
        },
        props: {
            state: Number,
        },
        data() {
            return {
                span: 6,
                span4: 5,
                span12: 12,
                span10: 10,
                objFltrate: {
                    show: false,
                    localStorage: 'fltrate_trade',
                    checkedCities: ["thirdOrderId", "tradeType", "shopRate", "merchantBeanCount", "amount", "shopName", "createTime", "costFee", "userBeanCount", "totalSettleFee", "byFenzhangFee", "settlementAmout"],
                    itemsOptions: [
                        {value: "thirdOrderId", name: "交易流水号"},
                        {value: "outTransactionId", name: "渠道流水号"},
                        {value: "amount", name: "金额"},
                        {value: "errorMessage", name: "失败原因"},
                        {value: "openid", name: "openID"},
                        {value: "userId", name: "用户ID"},
                        {value: "userPhone", name: "用户手机号"},
                        {value: "shopId", name: "店铺ID"},
                        {value: "shopName", name: "店铺名称"},
                        {value: "merchantPlatId", name: "商户id"},
                        {value: "tradeType", name: "交易类型"},
                        {value: "payType", name: "支付类型"},
                        {value: "payWay", name: "支付方式"},
                        {value: "createTime", name: "交易日期"},
                        {value: "profitSharing", name: "是否分账"},
                        {value: "shopRate", name: "门店费率"},
                        {value: "settlementAmount", name: "门店结算金额"},
                        {value: "costFee", name: "渠道手续费"},
                        {value: "totalSettleFee", name: "渠道结算金额"},
                        {value: "userBeanCount", name: "用户返豆"},
                        {value: "merchantBeanCount", name: "商家惠豆"},
                        {value: "byFenzhangFee", name: "公司分账金额"},
                        {value: "profitStatus", name: "分润状态"},
                    ],
                },
                orderStatusMap,
                tradeTypeMap,
                payTypeMap,
                payWayMap,
                pickerOptions,
                list: [],
                date: [],
                loading: false,
                download: false,
                totalData: 0, // 总条数
                pageSize: 20,
                currentPage: 0,
                where: {
                    pageNum: 0,
                    pageSize: 0,
                    obj: {
                        amount: "",
                        status: "",
                        shopId: "",
                        merchantPlatId: "",
                        tradeType: "",
                        profitSharing: "",
                        payType: "",
                        payWay: "",
                        createStartDate: "",
                        createEndDate: ""
                    }
                },
                total: {
                    count: 0,  // 总笔数
                    amount: 0, // 总提现金额
                    totalSettleFee: 0,
                    settlementAmount: 0,
                    byFenzhangFee: 0,
                },
            };
        },
        methods: {
            listData() {
                this.loading = true;
                this.where.obj.createStartDate = this.date ? this.date[0] : "";
                this.where.obj.createEndDate = this.date ? this.date[1] : "";
                this.where.pageNum = this.currentPage;
                this.where.pageSize = this.pageSize;
                this.$post(this.$api.traderDetail, this.where).then((res) => {
                    this.list = res.data.records;
                    this.totalData = res.data.total;
                    this.currentPage = res.data.current;
                    this.loading = false;
                    this.getTotal();
                });
            },
            exportData() {
                this.download = true;
                this.where.obj.createStartDate = this.date ? this.date[0] : "";
                this.where.obj.createEndDate = this.date ? this.date[1] : "";
                this.$post(this.$api.traderDetailExport, {...this.where, export: true}, true).then((res) => {
                    exportFile(res.data, decodeURI(res.headers.filename));
                    this.download = false;
                });
            },
            changeDate(obj) {
                if (obj) {
                    this.where.obj.createStartDate = obj[0];
                    this.where.obj.createEndDate = obj[1];
                }
            },
            immediUpdate(e) {
                this.$nextTick(() => {
                    console.log(e)
                    if(e==null){
                        this.where.obj.createStartDate = '';
                        this.where.obj.createEndDate = '';
                    }
                });
            },
            currentChange(e) {
                this.currentPage = e;
                this.listData();
            },
            handleSizeChange(e) {
                this.pageSize = e;
                this.listData();
            },
            getTotal() {
                this.$post(this.$api.traderDetailStatistics, this.where).then((res) => {
                    if (res.code === 1) {
                        this.total = res.data;
                    }
                });
            },
            getFltrate() {
                let obj = localStorage.getItem(this.objFltrate.localStorage);
                if (obj) {
                    this.objFltrate.checkedCities = JSON.parse(obj);
                }
            },
        },
        created() {
            this.date = [];
            let now = new Date();
            // this.date.push(formatDate(new Date(now.getFullYear(), now.getMonth(), 1),"yyyy-MM-dd"));
            this.date.push(formatDate(now, "yyyy-MM-dd"));
            this.date.push(formatDate(now, "yyyy-MM-dd"));
            this.listData();
            this.getFltrate();
        },
    };
</script>
<style lang="scss" scoped>
    .total-header {
        margin-bottom: 0px !important;
        margin-top: 15px;

        .el-col {
            margin-right: 15px;

            :last-child {
                margin-right: 0px;
            }
        }
    }

    .el-row {
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .el-col {
        border-radius: 4px;
    }

    .header-form {
        height: 40px;
        padding: 10px 0 10px 0;
    }

    .span4 {
        background: rgb(217, 236, 255);
        font-size: 16px;
        padding: 30px 0;
        text-align: center;
        vertical-align: center;

        span {
            font-size: 18px;
        }
    }

    /deep/ .el-input-number.is-controls-right .el-input__inner {
        padding-left: 2px;
        padding-right: 32px;
    }

    .endDate {
        color: #c0c4cc;
    }

    .el-image {
        height: 100px;
        width: 100px;
    }

    .el-select {
        width: 120px;
    }

    .status1 {
        color: #E6A23C;
    }

    .status2 {
        color: #67C23A;
    }

    .status8 {
        color: #F56C6C;
    }

    .status4 {
        color: #909399;
    }

    /*.OPERATION_CENTER,.AD_PAY{
        color: #409EFF;
    }
    .OPERATION_CENTER,.AD_PAY{
        color: #a84a26;
    }*/

    .el-drawer__header span:focus {
        outline: 0 !important;
    }
</style>
